import { useRecaptcha } from '@st/nuxt-recaptcha/composables/useRecaptcha'
import type { EmailStepData } from './types'

export function useUserAuthEmailCodeSent(emailStepData: Ref<EmailStepData>) {
  const { getToken, isLoadingRecaptcha, recaptchaQueryParam } = useRecaptcha()

  const { execute, error, status } = useStFetch(
    '/user/auth/provider/email/2fa/send',
    {
      method: 'post',
      body: computed(() => emailStepData.value),
      immediate: false,
      watch: false,
      query: recaptchaQueryParam,
    },
  )

  async function sendCode() {
    await getToken('/user/auth/provider/email/2fa/send')
    await execute()
  }

  return {
    sendCode,
    error,
    status,
    isLoadingRecaptcha,
  }
}
